.App {
  text-align: left;
  padding: 4vmin;
  max-width: 800px;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  /* font-size: calc(10px + 2vmin); */
  /* color: white; */
}

.para-text {
  padding-bottom: 1.5vh;
  font-size: calc(10px + 1.4vmin);
  white-space: pre-wrap;
  /* font-size: calc(10px); */
}

.para-text-instruction {
  padding-top: 0.75vh;
  padding-bottom: 2.25vh;
  font-size: calc(10px + 1.6vmin);
  white-space: pre-line;
  /* font-size: calc(10px); */
}

.answer-feedback-incorrect {
  display:block;
  background-color: #EE204D;
  /* border-style: solid;
  border-width: 2px;
  border-color: #dddddd; */
  padding: 1vh;
  padding-bottom: 1.5vh;
  font-size: calc(10px + 1.0vmin);
}

.answer-feedback {
  padding-bottom: 1.5vh;
  font-size: calc(10px + 1.0vmin);
}

.answer-feedback-resubmit {
  display:block;
  background-color: #F05E23;
  /* border-style: solid;
  border-width: 2px;
  border-color: #dddddd; */
  padding: 1vh;
  padding-bottom: 1.5vh;
  font-size: calc(10px + 1.0vmin);
}

.answer-feedback-correct {
  display:block;
  background-color: #50C878;
  /* border-style: solid;
  border-width: 2px;
  border-color: #dddddd; */
  padding: 1vh;
  padding-bottom: 1.5vh;
  font-size: calc(10px + 1.0vmin);
}

.questions-text {
  padding-bottom: 0.0vh;
  font-size: calc(10px + 1.25vmin);
}

.answers-text-selected {
  background-color: #B57EDC;
}

.question-text {
  padding-bottom: 1.25vh;
  font-size: calc(10px + 1.25vmin);
}

.answer-text {
  padding-bottom: 1vh;
}

.hint-text {
  display:block;
  /* background-color: #fffdd0; */
  background-color: #ffffff;
  /* border-style: solid;
  border-width: 2px;
  border-color: #dddddd; */
  padding: 0.5vh;
  /* font-size: calc(10px + 1vmin); */
  /* padding-bottom: 1.5vh; */
  font-size: calc(10px + 1vmin);
  color: #888888;
}

.submit-text-green {
  display:block;
  background-color: #50C878;
  border-style: dotted;
  border-width: 2px;
  border-color: #bbbbbb;
  padding: 1vh;
  /* font-size: calc(10px + 1vmin); */
  /* padding-bottom: 1.5vh; */
}

.submit-text-red {
  display:block;
  background-color: #EE204D;
  border-style: dotted;
  border-width: 2px;
  border-color: #bbbbbb;
  padding: 1vh;
  /* font-size: calc(10px + 1vmin); */
  /* padding-bottom: 1.5vh; */
}

.master-component {
  overflow:auto;
  overscroll-behavior-y: contain;
}

.top-component {
  /* display:block;
  background-color: #eeeeee;
  border-style: dotted;
  border-width: 2px;
  border-color: #bbbbbb; */
  /* padding-top: 2vh;
  padding-bottom: 1vh; */
  /* padding-bottom: 1.5vh; */
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  /* height: 8vh; */
}

.submit-component {
  /* display:block;
  background-color: #eeeeee;
  border-style: dotted;
  border-width: 2px;
  border-color: #bbbbbb; */
  /* padding-top: 2vh;
  padding-bottom: 1vh; */
  /* padding-bottom: 1.5vh; */
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  /* height: 8vh; */
}

.hint-component {
  /* display:block;
  background-color: #eeeeee;
  border-style: dotted;
  border-width: 2px;
  border-color: #bbbbbb; */
  padding-top: 2vh;
  padding-bottom: 1vh;
  /* padding-bottom: 1.5vh; */
}

.radio-clickable {
  display:block;
  /* background-color: #ffffff; */
  border-style: dotted;
  /* border-style: solid; */
  border-width: 1px;
  border-color: #cccccc;
  /* border-color: #bbbbbb; */
  padding: 1vh;
  font-size: calc(10px + 1.1vmin);
}

.wide-button {
  display:block;
  /* border-style: solid;
  border-width: 1px; */
  /* padding-top: 2vh;
  padding-bottom: 2vh;
  padding-left: 2vh;
  padding-right: 2vh; */
}

.submit-button-deactivated {
  background-color: #CCCCCC;
  /* background-color: #1B48E0; */
  border: none;
  color: white;
  padding: 2vh 2vh;
  text-align: center;
  text-decoration: none;
  width:100%;
  display: block;
  font-size: calc(10px + 2vmin);
  color: #EEEEEE;
  height: 6vh;
}

.score-component {
  display:block;
  text-align: center;
  /* vertical-align: bottom; */
  font-size: calc(10px + 1.2vmin);
  color: #ffffff;
}

.ranking-left-component {
  display:block;
  text-align: center;
  /* vertical-align: bottom; */
  font-size: calc(10px + 1.2vmin);
  color: #ffffff;
}

.submit-button-activated {
  background-color: #007FFF;
  /* background-color: #1B48E0; */
  border: none;
  color: white;
  /* padding: 2vh 2vh; */
  text-align: center;
  text-decoration: none;
  width:100%;
  display: block;
  font-size: calc(10px + 1.2vmin);
  color: #FFFFFF;
  /* height: 10vh; */
  height: 6vh;
  border-radius: 0.5vmin 0.5vmin 0.5vmin 0.5vmin;
}

.rank-button-green {
  /* Light red */
  background-color: #50C878;
  /* background-color: #1B48E0; */
  border: none;
  color: white;
  /* padding: 2vh 2vh; */
  text-align: center;
  text-decoration: none;
  width:100%;
  display: block;
  font-size: calc(10px + 1.2vmin);
  color: #FFFFFF;
  /* height: 10vh; */
  height: 6vh;
  border-radius: 0.5vmin 0.5vmin 0.5vmin 0.5vmin;
}

.rank-button-red {
  /* Light red */
  background-color: #EE204D;
  /* background-color: #1B48E0; */
  border: none;
  color: white;
  /* padding: 2vh 2vh; */
  text-align: center;
  text-decoration: none;
  width:100%;
  display: block;
  font-size: calc(10px + 1.2vmin);
  color: #FFFFFF;
  /* height: 10vh; */
  height: 6vh;
  border-radius: 0.5vmin 0.5vmin 0.5vmin 0.5vmin;
}

.skip-button {
  /* Light red */
  background-color: #bbbbbb;
  /* background-color: #1B48E0; */
  border: none;
  color: white;
  /* padding: 2vh 2vh; */
  text-align: center;
  text-decoration: none;
  width:100%;
  display: block;
  font-size: calc(10px + 1.2vmin);
  color: #FFFFFF;
  /* height: 10vh; */
  height: 6vh;
  border-radius: 0.5vmin 0.5vmin 0.5vmin 0.5vmin;
}

.hint-padding {
  /* padding-top: 10vh; */
}

.hint-button {
  background-color: #ffffff;
  /* background-color: #1B48E0; */
  border: none;
  /* color: white; */
  /* padding: 15px 32px; */
  padding-top: 1vh;
  padding-bottom: 1vh;
  padding-left: 0.5vh;
  padding-right: 0.5vh;
  text-align: left;
  /* text-decoration: none; */
  width:100%;
  display: block;
  font-size: calc(10px + 1vmin);
  color: #bbbbbb;
}

.submit-hint {
  background-color: #B57EDC;
  /* border-style: dotted; */
  border-top-style: solid;
  border-top-width: 1px;
  /* border-color: #dddddd; */
  border-color: #dddddd;
  padding-top: 1.2vh;
  padding-bottom: 1.2vh;
  padding-left: 0.75vh;
  padding-right: 0.75vh;
  /* margin-left: 1vh;
  margin-right: 1vh; */
  font-size: calc(10px + 1.25vmin);
  color: #ffffff;
  text-align: center;
}

.top-background-color {
  background-color: #007FFF; /* blue */
}

.ranklist-background-color {
  background-color: #007FFF; /* blue */
}

.top-background {
  vertical-align: middle;
  /* border-style: dotted; */
  border-bottom-style: solid;
  border-bottom-width: 1px;
  /* border-color: #dddddd; */
  border-color: #dddddd;
  padding-top: 0.75vh;
  padding-bottom: 0.75vh;
  padding-left: 0.75vh;
  padding-right: 0.75vh;
  /* margin-left: 1vh;
  margin-right: 1vh; */
  font-size: calc(10px + 1.1vmin);
}

.ranking-button-wrapper {
  background-color: #007FFF;
  /* border-style: dotted; */
  border-top-style: solid;
  border-top-width: 1px;
  border-color: #dddddd;
  /* border-color: #888888; */
  padding-top: 0.4vh;
  padding-bottom: 0.4vh;
  padding-left: 0.2vh;
  padding-right: 0.2vh;
  /* margin-left: 1vh;
  margin-right: 1vh; */
}

.submit-button-wrapper {
  background-color: #ffffff;
  /* border-style: dotted; */
  border-top-style: solid;
  border-top-width: 1px;
  border-color: #dddddd;
  /* border-color: #888888; */
  padding-top: 0.75vh;
  padding-bottom: 0.75vh;
  padding-left: 0.75vh;
  padding-right: 0.75vh;
  /* margin-left: 1vh;
  margin-right: 1vh; */
}

.submit-hint-incorrect {
  /* red */
  /* background-color: #CE2029; */
  background-color: #FFFFFF;
  /* border-color: #CE2029; */
}

.submit-hint-correct {
  /* green */
  /* background-color: #4CBB17; */
  /* border-color: #4CBB17; */
  background-color: #FFFFFF;
}

.submit-hint-correct {
  /* green */
  /* background-color: #4CBB17; */
  /* border-color: #4CBB17; */
  background-color: #FFFFFF;
}

.skip-question-text {
  /* text-decoration: underline; */
  color: #4CBB17;
}

.page-bottom-behind-submit-button-padding-1 {
  height: 2vh;
}

.page-bottom-behind-submit-button-padding-2 {
  height: 6vh;
}

.page-top-behind-top-padding-long {
  height: 12vh;
}

.page-top-behind-top-padding-short {
  height: 3vh;
}

.App-link {
  color: #09d3ac;
}

.columns-wrapper {
  display:table;
  width: 100%;
}

.columns {
  display:table-row;
}

.columns>div{
  display:table-cell
}

/* .columns {
  width:100%;
  height:100%;
  top:0px;
  left:0px;
  position:absolute;
} */

.left-column-55 {
  width: 55%;
  padding-right: 2vh;
  font-size: calc(10px + 1.25vmin);
}

.right-column-45 {
  width: 45%;
  padding-left: 2vh;
  font-size: calc(10px + 1.25vmin);
}

.left-column-45 {
  width: 45%;
  padding-right: 2vh;
  font-size: calc(10px + 1.25vmin);
}

.right-column-55 {
  width: 55%;
  padding-left: 2vh;
  font-size: calc(10px + 1.25vmin);
}

.left-column-60 {
  width: 65%;
  padding-left: 1vh;
  padding-right: 0.5vh;
  vertical-align: middle;
  font-size: calc(10px + 1.1vmin);
}

.right-column-40 {
  width: 35%;
}

.left-column-50-bottom {
  padding-right: 0.5vh;
  width: 50%;
  vertical-align: middle;
}

.right-column-50-bottom {
  padding-left: 0.5vh;
  width: 50%;
  vertical-align: middle;
}

.left-column-40-bottom {
  padding-right: 0.5vh;
  width: 40%;
  vertical-align: middle;
}

.right-column-60-bottom {
  padding-left: 0.5vh;
  width: 60%;
  vertical-align: middle;
}

.left-column-top {
  padding-right: 3vh;
  width: 20%;
  vertical-align: middle;
  float: right;
}

.right-column-top {
  padding-left: 0.5vh;
  width: 80%;
  vertical-align: middle;
}

.matching-not-selected {
  border-style: solid;
  border-width: 4px;
  border-bottom-width: 5px;
  border-color: #EE204D; /* red */
  /* background-color: #EE204D; */
}

.matching-select {
  /* matching select width */
  min-width: 48px;
  width: 9vh;
  font-size: calc(10px + 1.1vmin);
}

.matching-selected {
  border-style: solid;
  border-width: 4px;
  border-bottom-width: 5px;
  border-color: #B57EDC; /* purple */
  /* background-color: #B57EDC; */
}

.image-score {
  height: 5vh;
}

.matching-option {
  background-color: #00f;
}

.matching-option {
  background-color: #00f;
}

.ranklist-table {
  border-collapse: collapse;
  border: 1px;
  border-style: solid;
  border-color: #cccccc;
  /* padding: 20px; */
}

.ranklist-cell {
  font-size: calc(10px + 1.1vmin);
  border: 1px;
  border-style: solid;
  border-color: #cccccc;
  text-align: center;
  padding: 3px;
}

.ranklist-highlight-correct-cell {
  background-color: #50C878;
}

.ranklist-highlight-current-user-cell {
  background-color: #007FFF;
}
